<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="displayedItems"
    :mobile-breakpoint="0"
    :loading="loading"
    :class="{ 'content-loading': loading }"
    :search="search"
    style="overflow: auto;"
    scrollable
    disable-pagination
    hide-default-footer
    disable-sort
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.is_hidden="{ item }">
      <v-chip
        :color="item.is_hidden ? 'grey lighten-4' : 'green accent-2'"
        class="caption persist-opacity"
        label
        small
      >
        {{ $t(item.is_hidden ? 'hidden' : 'visible') }}
      </v-chip>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :item="item"
        :loading="$store.getters.loading[`delete:api/products/${item.id}`]"
      />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.is_hidden>
          <v-chip
            :color="item.is_hidden ? 'grey lighten-4' : 'green accent-2'"
            class="caption persist-opacity"
            label
            small
          >
            {{ $t(item.is_hidden ? 'hidden' : 'visible') }}
          </v-chip>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';

export default {
  name: 'ProductsTable',

  components: { BaseExpandedTableRow, BaseActionMenu },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        // {
        //   text: this.$t('product_group_id'),
        //   value: 'product_group_id',
        //   hidden: 'xsOnly',
        // },
        {
          text: this.$t('title_lt'),
          value: 'title_lt',
        },
        {
          text: this.$t('title_en'),
          value: 'title_en',
        },
        {
          text: this.$t('price'),
          value: 'price',
          hidden: 'xsOnly',
        },
        {
          value: 'is_hidden',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    displayedItems() {
      if (!this.items) {
        return [];
      }
      return this.items.map((r) => ({
        ...r,
      }));
    },
  },

  methods: {
    getRowActions() {
      const actions = [
        {
          callback: (p) => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        // {
        //   callback: (p) => this.$emit('delete', p),
        //   label: this.$t('delete'),
        //   icon: 'mdi-delete',
        // },
      ];
      return actions;
    },
  },
};
</script>

<style scoped></style>
