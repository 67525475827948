<template>
  <div>
    <div class="d-flex align-center pa-4">
      <h1 class="title">
        {{ $t('products') }}
      </h1>
      <v-spacer />
      <BasePrimaryActionButton
        :label="$t('create_product')"
        @click="$router.push({ name: 'createProduct' })"
      />
    </div>

    <BaseFilter
      :filter-params="productFilterParams"
      @update="$router.push({ name: 'products', query: { ...$event } }).catch(() => {})"
    />

    <div class="border-t" :class="{ 'd-flex justify-space-between': $vuetify.breakpoint.smAndUp }">
      <Categories @filter:category="filterByCategory" @filter:all="filterAll" />

      <BaseTableLoader
        :loading="!products"
        :class="{ 'table-desktop': $vuetify.breakpoint.smAndUp }"
      >
        <ProductsTable
          :items="products"
          :loading="$store.getters.loading['get:api/products']"
          :search="$route.query.search"
          @edit="$router.push({ name: 'editProduct', params: { id: $event.id } })"
          @delete="deleteProduct"
        />
      </BaseTableLoader>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import BaseTableLoader from '@/components/base/BaseTableLoader';
import ProductsTable from '@/components/ProductsTable';
import Categories from '@/components/Categories';
import BaseFilter from '@/components/base/BaseFilter';

export default {
  name: 'Products',

  components: {
    BaseFilter,
    Categories,
    ProductsTable,
    BaseTableLoader,
    BasePrimaryActionButton,
  },

  created() {
    if (!this.products) {
      this.fetchProducts(this.$route.query);
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (
      JSON.stringify(this.productFilterParams) !== JSON.stringify(to.query) &&
      to.name === 'products'
    ) {
      this.fetchProducts(to.query);
    }
    next();
  },

  computed: mapState('products', ['products', 'productFilterParams']),

  methods: {
    ...mapActions('products', ['fetchProducts', 'deleteProduct']),

    filterByCategory(groupId) {
      this.$router.push({ name: 'products', query: { product_group_id: groupId } }).catch(() => {});
    },

    filterAll() {
      this.$router.push({ name: 'products' }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
