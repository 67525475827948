<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="displayedItems"
    :mobile-breakpoint="0"
    :loading="loading"
    :class="{ 'content-loading': loading }"
    class="overflow-auto"
    scrollable
    disable-pagination
    hide-default-footer
    disable-sort
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.contact_persons="{ item }">
      <div v-for="(contact, index) in item.contacts" :key="contact.id">
        <div class="d-flex justify-space-between align-center">
          {{ contact.first_name }} {{ contact.last_name }}
          <div style="width:150px">
            <v-tooltip
              v-for="action in getClientContactActions(contact, item)"
              :key="action.label"
              open-delay="500"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click.stop="
                    onActionClick({
                      action,
                      domEvent: $event,
                      user: item.contacts[index],
                    })
                  "
                >
                  <v-icon :color="getContactActionColor(action)">
                    {{ action.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ action.label }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:item.email="{ item }">
      <div v-for="contact in item.contacts" :key="contact.id">
        <div>
          <a :href="`mailto:${contact.email}`" class="link" style="line-height: 36px;">
            {{ contact.email }}
          </a>
        </div>
      </div>
    </template>

    <template v-slot:item.importance="{ item }">
      <div v-for="importanceItem in importanceItems" :key="importanceItem.value">
        <div v-if="item.importance === importanceItem.value">
          {{ importanceItem.label }}
        </div>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :item="item"
        :loading="$store.getters.loading[`delete:api/clients/${item.id}`]"
      />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.contact_persons>
          <div v-for="(contact, index) in item.contacts" :key="contact.id">
            <div class="d-flex justify-space-between align-center">
              {{ contact.first_name }} {{ contact.last_name }}
              <div class="mr-5" style="width:150px">
                <v-tooltip
                  v-for="action in getClientContactActions(contact, item)"
                  :key="action.label"
                  open-delay="500"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      @click.stop="
                        onActionClick({
                          action,
                          domEvent: $event,
                          user: item.contacts[index],
                        })
                      "
                    >
                      <v-icon :color="getContactActionColor(action)">
                        {{ action.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ action.label }}
                  </span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:item.email>
          <div v-for="contact in item.contacts" :key="contact.id">
            <div>
              <a :href="`mailto:${contact.email}`" class="link" style="line-height: 36px;">
                {{ contact.email }}
              </a>
            </div>
          </div>
        </template>

        <template v-slot:item.importance>
          <div v-for="importanceItem in importanceItems" :key="importanceItem.value">
            <div v-if="item.importance === importanceItem.value">
              {{ importanceItem.label }}
            </div>
          </div>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import { mapGetters } from 'vuex';

export default {
  name: 'ClientsTable',

  components: { BaseActionMenu, BaseExpandedTableRow },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('clients', ['importanceItems']),

    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
        },
        {
          text: this.$t('importance'),
          value: 'importance',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('contact_persons'),
          value: 'contact_persons',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('email'),
          value: 'email',
          hidden: 'smAndDown',
        },

        {
          text: this.$t('manufacturing_term'),
          value: 'manufacturing_term_in_days',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    displayedItems() {
      if (!this.items) {
        return [];
      }
      return this.items.map((r) => ({
        ...r,
      }));
    },
  },

  methods: {
    getContactActionColor(action) {
      let color = '';
      if (action.colored) {
        if (action.icon === 'mdi-account-minus') {
          color = 'red accent-1';
        } else {
          color = 'green accent-2';
        }
      }
      return color;
    },

    getRowActions() {
      const actions = [
        {
          callback: (p) => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: (p) => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ];
      return actions;
    },

    getClientContactActions(contact, client) {
      const actions = [
        {
          callback: (p) => {
            if (contact.user) {
              this.$emit('destroy:login', { contact: p, client });
            } else {
              this.$emit('create:login', p);
            }
          },
          label: contact.user ? this.$t('destroy_login') : this.$t('create_login'),
          icon: contact.user ? 'mdi-account-minus' : 'mdi-account-plus',
          colored: true,
        },
      ];

      if (contact.user) {
        actions.push(
          {
            callback: (p) => this.$emit('change:password', p),
            label: this.$t('change_password'),
            icon: 'mdi-lock-reset',
            colored: false,
          },
          {
            callback: () => this.$emit('impersonate', contact.user),
            label: this.$t('impersonate'),
            icon: 'mdi-account',
            colored: false,
          },
        );
      }

      return actions;
    },

    onActionClick({ action, domEvent, user }) {
      const payload = user || domEvent;
      action.callback(payload);
    },
  },
};
</script>

<style scoped></style>
