var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"overflow-auto",class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.displayedItems,"mobile-breakpoint":0,"loading":_vm.loading,"scrollable":"","disable-pagination":"","hide-default-footer":"","disable-sort":""},on:{"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.contact_persons",fn:function({ item }){return _vm._l((item.contacts),function(contact,index){return _c('div',{key:contact.id},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(contact.first_name)+" "+_vm._s(contact.last_name)+" "),_c('div',{staticStyle:{"width":"150px"}},_vm._l((_vm.getClientContactActions(contact, item)),function(action){return _c('v-tooltip',{key:action.label,attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onActionClick({
                    action,
                    domEvent: $event,
                    user: item.contacts[index],
                  })}}},on),[_c('v-icon',{attrs:{"color":_vm.getContactActionColor(action)}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(action.label)+" ")])])}),1)])])})}},{key:"item.email",fn:function({ item }){return _vm._l((item.contacts),function(contact){return _c('div',{key:contact.id},[_c('div',[_c('a',{staticClass:"link",staticStyle:{"line-height":"36px"},attrs:{"href":`mailto:${contact.email}`}},[_vm._v(" "+_vm._s(contact.email)+" ")])])])})}},{key:"item.importance",fn:function({ item }){return _vm._l((_vm.importanceItems),function(importanceItem){return _c('div',{key:importanceItem.value},[(item.importance === importanceItem.value)?_c('div',[_vm._v(" "+_vm._s(importanceItem.label)+" ")]):_vm._e()])})}},{key:"item.actions",fn:function({ item }){return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"item":item,"loading":_vm.$store.getters.loading[`delete:api/clients/${item.id}`]}})]}},{key:"expanded-item",fn:function({ item }){return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.contact_persons",fn:function(){return _vm._l((item.contacts),function(contact,index){return _c('div',{key:contact.id},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_vm._v(" "+_vm._s(contact.first_name)+" "+_vm._s(contact.last_name)+" "),_c('div',{staticClass:"mr-5",staticStyle:{"width":"150px"}},_vm._l((_vm.getClientContactActions(contact, item)),function(action){return _c('v-tooltip',{key:action.label,attrs:{"open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onActionClick({
                        action,
                        domEvent: $event,
                        user: item.contacts[index],
                      })}}},on),[_c('v-icon',{attrs:{"color":_vm.getContactActionColor(action)}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(action.label)+" ")])])}),1)])])})},proxy:true},{key:"item.email",fn:function(){return _vm._l((item.contacts),function(contact){return _c('div',{key:contact.id},[_c('div',[_c('a',{staticClass:"link",staticStyle:{"line-height":"36px"},attrs:{"href":`mailto:${contact.email}`}},[_vm._v(" "+_vm._s(contact.email)+" ")])])])})},proxy:true},{key:"item.importance",fn:function(){return _vm._l((_vm.importanceItems),function(importanceItem){return _c('div',{key:importanceItem.value},[(item.importance === importanceItem.value)?_c('div',[_vm._v(" "+_vm._s(importanceItem.label)+" ")]):_vm._e()])})},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }