<template>
  <div class="page-wrapper">
    <div class="d-flex align-center pa-4">
      <h1 class="title">
        {{ $t('clients') }}
      </h1>
      <v-spacer />
      <BasePrimaryActionButton
        :label="$t('create_client')"
        @click="$router.push({ name: 'createClient' })"
      />
    </div>

    <BaseFilter
      :filter-params="clientFilterParams"
      @update="$router.push({ name: 'clients', query: { ...$event } }).catch(() => {})"
    />

    <BaseTableLoader :loading="!clients">
      <ClientsTable
        :items="clients"
        :loading="$store.getters.loading['get:api/clients']"
        @edit="$router.push({ name: 'editClient', params: { id: $event.id } }).catch(() => {})"
        @delete="deleteClient"
        @change:password="
          $router.push({ name: 'changePassword', params: { id: $event.id } }).catch(() => {})
        "
        @create:login="
          $router.push({ name: 'createLogin', params: { id: $event.id } }).catch(() => {})
        "
        @destroy:login="destroyLogin"
        @impersonate="impersonateUser"
      />
    </BaseTableLoader>
    <router-view />
  </div>
</template>

<script>
import BaseTableLoader from '@/components/base/BaseTableLoader';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import BaseFilter from '@/components/base/BaseFilter';
import ClientsTable from '@/components/ClientsTable';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Clients',

  components: { ClientsTable, BasePrimaryActionButton, BaseTableLoader, BaseFilter },

  created() {
    this.fetchClients(this.$route.query);
  },

  computed: mapState('clients', ['clients', 'clientFilterParams']),

  beforeRouteUpdate(to, from, next) {
    if (
      JSON.stringify(this.clientFilterParams) !== JSON.stringify(to.query) &&
      to.name === 'clients'
    ) {
      this.fetchClients(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('clients', ['fetchClients', 'deleteClient']),
    ...mapActions('contacts', ['destroyLogin']),
    ...mapActions('auth', ['impersonateUser']),
  },
};
</script>

<style scoped></style>
