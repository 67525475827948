<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          :value="filterParams.search"
          :label="$t('search')"
          autocomplete="off"
          clearable
          prepend-icon="mdi-magnify"
          @input="debounceUpdateFilter('search', $event)"
          @click:clear="updateFilter('search', null)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BaseFilter',

  props: {
    filterParams: {
      type: Object,
      required: true,
    },
  },

  methods: {
    updateFilter(key, value) {
      const params = { ...this.filterParams };
      if (value) {
        params[key] = value;
      } else {
        delete params[key];
      }
      this.$emit('update', params);
    },

    debounceUpdateFilter(key, value) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.updateFilter(key, value);
      }, 600);
    },
  },
};
</script>

<style scoped></style>
