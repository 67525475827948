<template>
  <div class="pa-7 border-r">
    <div
      v-if="productGroups"
      class="pb-1 clickable"
      :class="{ 'selected-category': !$route.query.product_group_id }"
      @click="$emit('filter:all')"
    >
      Visi
    </div>
    <div v-for="group in productGroups" :key="group.id">
      <div>
        <div
          :class="{ 'selected-category': group.id === +$route.query.product_group_id }"
          class="pb-1 clickable"
          @click="$emit('filter:category', group.id)"
        >
          {{ group.title }}
        </div>
        <div v-if="group.subgroup">
          <div v-for="subgroup in group.subgroup" :key="subgroup.id" class="pl-5">
            <div
              :class="{ 'selected-category': subgroup.id === +$route.query.product_group_id }"
              class="pb-1 clickable"
              @click="$emit('filter:category', subgroup.id)"
            >
              {{ subgroup.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Categories',

  computed: {
    ...mapState('products', ['productGroups']),
  },

  methods: {},
};
</script>

<style scoped></style>
